import * as Lib from '.'

export const FEATURES_TYPES = {
  DISPLAY_USER_SETTINGS: 'DISPLAY_USER_SETTINGS',
  PRODUCT_RECHARGE_NOTIFY: 'PRODUCT_RECHARGE_NOTIFY',
  WHOLESALE_REQUIRE_LOGIN_ALL_ROUTES: 'WHOLESALE_REQUIRE_LOGIN_ALL_ROUTES',
  GOOGLE_TAG_MANAGER: 'GOOGLE_TAG_MANAGER',
  PAY_AT_DOOR_PAYMENT_METHOD: 'PAY_AT_DOOR_PAYMENT_METHOD',
  SENTRY: 'SENTRY',
  ADDRESS_NATIVE_SELECT: 'ADDRESS_NATIVE_SELECT',
} as const

export const featuresStatus: Lib.T.FeatureStatus = {
  [FEATURES_TYPES.DISPLAY_USER_SETTINGS]: {
    development: true,
    stage: true,
    production: true,
  },
  [FEATURES_TYPES.PRODUCT_RECHARGE_NOTIFY]: {
    development: false,
    stage: false,
    production: false,
  },
  [FEATURES_TYPES.WHOLESALE_REQUIRE_LOGIN_ALL_ROUTES]: {
    development: true,
    stage: true,
    production: true,
  },
  [FEATURES_TYPES.GOOGLE_TAG_MANAGER]: {
    development: false,
    stage: true,
    production: true,
  },
  [FEATURES_TYPES.PAY_AT_DOOR_PAYMENT_METHOD]: {
    development: true,
    stage: true,
    production: true,
  },
  [FEATURES_TYPES.SENTRY]: {
    development: false,
    stage: false,
    production: false,
  },

  [FEATURES_TYPES.ADDRESS_NATIVE_SELECT]: {
    development: false,
    stage: false,
    production: false,
  },
}
